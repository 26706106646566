import React from "react";

const Button = ({
  style = {},
  className = "",
  isDisabled = false,
  type = "secondary",
  iconName = "",
  handelClick,
  children,
}) => {
  return (
    <button
      className={`button print ${type} ${
        isDisabled ? "disabled" : ""
      } ${className}`}
      style={style}
      onClick={handelClick}
    >
      {iconName === "" ? null : (
        <span className={`icon icon-${iconName}`}></span>
      )}
      {children}
    </button>
  );
};

export default Button;
